import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { Observable, ReplaySubject, Subject, merge } from 'rxjs';
import { distinct, pairwise, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { firebase } from 'minga/app/src/firebase';
import { RealtimeEventType } from 'minga/libraries/domain';
import { CONFIG } from 'minga/libraries/shared';

import { AuthInfoService } from '../minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '../store/Minga/services';
import { EventSubscription } from './RealtimeEvents.types';

@Injectable({ providedIn: 'root' })
export class RealtimeEvents implements OnDestroy {
  private _activeMingaHash: string;
  private _subscriptions: {
    [eventName in RealtimeEventType]?: Subject<EventSubscription>;
  } = {};
  private _firestoreUnsubscriber: firebase.Unsubscribe;
  private _userSubscriptions: {
    [userHash: string]: {
      [eventName in RealtimeEventType]?: Subject<EventSubscription>;
    };
  } = {};
  private _destroyedSubj = new ReplaySubject<void>(1);
  private _firestoreUserUnsubscribers: {
    [userHash: string]: firebase.Unsubscribe;
  } = {};

  constructor(
    private _authInfo: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
  ) {}

  public async init() {
    this._authInfo.authPersonHash$
      .pipe(takeUntil(this._destroyedSubj), distinct(), pairwise())
      .subscribe(([prevHash, currHash]) => {
        this._setupListener(prevHash, currHash);
      });

    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(minga => {
        if (minga) {
          this._activeMingaHash = minga.hash;
        }
      });
  }

  /**
   * Observe realtime events for the currently logged in user
   */
  public observe(
    types: RealtimeEventType | RealtimeEventType[],
  ): Observable<EventSubscription> {
    const typesArray = Array.isArray(types) ? types : [types];

    const validatedTypes = typesArray
      .map(type => $enum(RealtimeEventType).asValueOrDefault(type, null))
      .filter(t => t);

    const observables = validatedTypes.map(type =>
      this._getOrCreateSubject(type).asObservable(),
    );

    return merge(...observables);
  }

  /**
   * Observe realtime events for a specific user
   */
  public observeUser(
    userHash: string,
    types: RealtimeEventType | RealtimeEventType[],
  ): Observable<EventSubscription> {
    return new Observable<EventSubscription>(observer => {
      const typesArray = Array.isArray(types) ? types : [types];

      const validatedTypes = typesArray
        .map(type => $enum(RealtimeEventType).asValueOrDefault(type, null))
        .filter(t => t);

      const userSubjects = validatedTypes.map(type =>
        this._getOrCreateUserSubject(userHash, type).asObservable(),
      );

      // Set up the Firestore listener for this user
      this._setupUserListener(userHash);

      const mergedObservable = merge(...userSubjects).subscribe(event => {
        observer.next(event);
      });

      return () => {
        this._stopObservingUser(userHash);
        mergedObservable.unsubscribe();
      };
    });
  }

  private _getOrCreateSubject(type: RealtimeEventType) {
    if (!this._subscriptions[type]) {
      this._subscriptions[type] = new Subject<EventSubscription>();
    }

    return this._subscriptions[type];
  }

  private _getOrCreateUserSubject(userHash: string, type: RealtimeEventType) {
    if (!this._userSubscriptions[userHash]) {
      this._userSubscriptions[userHash] = {};
    }

    if (!this._userSubscriptions[userHash][type]) {
      this._userSubscriptions[userHash][type] =
        new Subject<EventSubscription>();
    }

    return this._userSubscriptions[userHash][type];
  }

  private _setupListener(
    prevAuthPersonHash: string,
    currAuthPersonHash: string,
  ) {
    if (!currAuthPersonHash) return;

    if (prevAuthPersonHash && this._firestoreUnsubscriber) {
      this._firestoreUnsubscriber();
    }

    try {
      this._firestoreUnsubscriber = this._firestoreQuery(
        currAuthPersonHash,
        true,
      );
    } catch (e) {
      console.error('realtime snapshot error', e);
    }
  }

  private _setupUserListener(userHash: string) {
    // If there's already a listener for this user, unsubscribe first
    if (this._firestoreUserUnsubscribers[userHash]) {
      this._firestoreUserUnsubscribers[userHash]();
    }

    try {
      this._firestoreUserUnsubscribers[userHash] = this._firestoreQuery(
        userHash,
        false,
      );
    } catch (e) {
      console.error('realtime snapshot error', e);
    }
  }

  private _firestoreQuery(userHash: string, isCurrentUser: boolean) {
    const twoMinsAgo = day().subtract(2, 'minutes');

    return firebase
      .firestore()
      .collection(CONFIG.collectionName)
      .doc(userHash)
      .collection(CONFIG.subCollection)
      .where('handledAt', '==', null)
      .where('createdAt', '>', twoMinsAgo.toDate())
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (this._isValidEvent(change)) {
            this._handleAddedDoc(change.doc, isCurrentUser ? '' : userHash);
          }
        });
      });
  }

  private async _handleAddedDoc(
    doc: firebase.firestore.QueryDocumentSnapshot,
    userHash?: string,
  ) {
    this._publishEvent(doc, userHash);
    this._updateHandledAt(doc);
  }

  private async _updateHandledAt(
    doc: firebase.firestore.QueryDocumentSnapshot,
  ) {
    try {
      await doc.ref.update({ handledAt: new Date() });
    } catch (e) {
      console.error('error updating realtime doc', e);
    }
  }

  private _publishEvent(
    doc: firebase.firestore.QueryDocumentSnapshot,
    userHash?: string,
  ) {
    const type = doc.data().type;
    const payload = doc.data().payload;
    const createdAt = doc.data().createdAt;

    if (userHash) {
      // Publish to user-specific subscriptions
      if (
        this._userSubscriptions[userHash] &&
        this._userSubscriptions[userHash][type]
      ) {
        this._userSubscriptions[userHash][type].next<EventSubscription>({
          type,
          payload,
          createdAt: createdAt.toDate(),
        });
      }
    } else {
      // Publish to global subscriptions
      if (this._subscriptions[type]) {
        this._subscriptions[type].next<EventSubscription>({
          type,
          payload,
          createdAt: createdAt.toDate(),
        });
      }
    }
  }

  private _isValidEvent(change: firebase.firestore.DocumentChange): boolean {
    return (
      change.type === 'added' &&
      // lets only display events for the active minga
      change.doc.data().mingaHash === this._activeMingaHash
    );
  }

  private _stopObservingUser(userHash: string): void {
    if (this._firestoreUserUnsubscribers[userHash]) {
      // Unsubscribe Firestore listener for the specific user
      this._firestoreUserUnsubscribers[userHash]();
      delete this._firestoreUserUnsubscribers[userHash];
    }
  }

  ngOnDestroy() {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    Object.values(this._firestoreUserUnsubscribers).forEach(unsubscribe =>
      unsubscribe(),
    );
  }
}
