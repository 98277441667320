import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, Observable, ReplaySubject, interval } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HallPassStatusEnum, IHallPassType } from 'minga/libraries/domain';
import { RealtimeEventType } from 'minga/libraries/domain';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { RealtimeEvents } from 'src/app/realtime-event/RealtimeEvents';
import { HallPassService } from 'src/app/services/HallPass';

import {
  HPM_DASHBOARD_FILTERS_INITIAL_STATE_TEACHER,
  HpmDashboardFilter,
  HpmDashboardTableItem,
  mapHallPassToDashboardItem,
} from '@modules/hallpass-manager';

import { SystemAlertCloseEvents } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MingaAudio } from '@shared/constants';
import { AudioPlayerService } from '@shared/services/audio-player';
import { CacheService } from '@shared/services/cache/cache.service';
import { CacheKey } from '@shared/services/cache/cache.types';
import { HallPassService as HallPassSharedService } from '@shared/services/hall-pass';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import {
  FORM_FIELDS,
  MyClassMessages,
} from '../constants/tt-my-class.constants';
import { MyClassPreferencesService } from './my-class-preferences.service';

// time between hall pass fetches 1 minute
const FETCH_INTERVAL_MS = 1000 * 60;
const POLL_INTERVAL_MS = 1000 * 10; // 10 seconds

@Injectable()
export class MyClassHallPassService implements OnDestroy {
  private _cachedTypes = this._cacheService.create<IHallPassType[]>(
    CacheKey.HALL_PASS_TYPES_WITH_INACTIVE,
    async data => {
      return this._hallPassSharedService.listTypes(false);
    },
    {
      ttl: 10,
    },
  );

  private _destroyedSubject = new ReplaySubject<void>(1);

  private _hallPassesByStudentSubject = new BehaviorSubject<
    Map<string, HpmDashboardTableItem>
  >(new Map());
  public hallPassesByStudent$ = this._hallPassesByStudentSubject.asObservable();

  public readonly timeTicker$ = interval(1000).pipe(
    takeUntil(this._destroyedSubject),
  );

  private _lastFetched: number = Date.now();

  private _soundEnabled = false;

  constructor(
    private _hallPassSharedService: HallPassSharedService,
    private _hallPassActions: HallPassActionsService,
    private _realtimeEvents: RealtimeEvents,
    private _hallPassService: HallPassService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _cacheService: CacheService,
    private _audioPlayerService: AudioPlayerService<MingaAudio>,
    private _preferencesService: MyClassPreferencesService,
  ) {
    this._handleHallPassApprovalEvents();

    this._preferencesService.preferences$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(preferences => {
        this._soundEnabled = preferences[FORM_FIELDS.ENABLED_SOUND] ?? false;

        // load sound so it can be played later
        if (this._soundEnabled) {
          this._audioPlayerService.addAudio([MingaAudio.POSITIVE_PING]); // CHANGE THIS WITH THE RIGHT SOUND
        }
      });

    // hall pass may be ended from the students id as well as
    // from my class so we need to subscribe to it
    this._hallPassService.hallPassDidEnd$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(id => {
        this._endPass(id);
      });

    this._hallPassActions.passCountdownChange$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(({ id, state, type }) => {
        if (type === 'update') {
          this.updatePass(id, state);

          if (
            (state === HallPassStatusEnum.ENDED ||
              state === HallPassStatusEnum.OVERDUE) &&
            this._soundEnabled
          ) {
            this._audioPlayerService.playAudio(MingaAudio.POSITIVE_PING); // CHANGE THIS WITH THE RIGHT SOUND
          }
        }

        if (type === 'remove') {
          this.removePass(id);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private async _fetchPasses() {
    const filters = {
      ...HPM_DASHBOARD_FILTERS_INITIAL_STATE_TEACHER,
      [HpmDashboardFilter.PASS_STATUS]: [
        HallPassStatusEnum.ACTIVE,
        HallPassStatusEnum.PENDING_APPROVAL,
        HallPassStatusEnum.OVERDUE,
      ],
      show_mine: false,
    };

    try {
      const passes = await this._hallPassSharedService.listPasses(filters);
      this._setPasses(passes);
    } catch (e) {
      this._systemSnackBar.open({
        type: 'error',
        message: MyClassMessages.FETCH_HALL_PASSES_ERROR,
      });
    } finally {
      this._lastFetched = Date.now();
    }
  }

  private _setPasses(passes: HallPassWithType.AsObject[]) {
    const now = day();
    const mapped = passes.map(item => mapHallPassToDashboardItem(item, now));
    const passMap = this._hallPassesByStudentSubject.value;

    mapped.forEach(pass => {
      const hash = pass?.recipientPersonView?.personHash;

      if (!hash) {
        console.warn('Hall pass missing recipient hash');
        return;
      }

      if (!passMap.has(hash)) {
        passMap.set(hash, pass);
      } else {
        const existingPass = passMap.get(hash);
        const userPasses = [existingPass, pass];
        // if user has multiple recent passes, we only want the most important since my class only shows 1
        // 1. Pending task precedents
        // 2. Otherwise most recent
        userPasses.sort((a, b) => {
          if (a.status.state === HallPassStatusEnum.PENDING_APPROVAL) {
            return -1;
          }

          if (b.status.state === HallPassStatusEnum.PENDING_APPROVAL) {
            return 1;
          }

          return b.status.end.getTime() - a.status.end.getTime();
        });

        passMap.set(hash, userPasses[0]);
      }
    });

    this._hallPassesByStudentSubject.next(passMap);
  }

  public updatePass(passId: number, state: HallPassStatusEnum, end?: Date) {
    const passes = this._hallPassesByStudentSubject?.value;
    if (passes) {
      passes.forEach((pass, key) => {
        if (pass.id === passId) {
          pass.status.state = state;

          if (end) {
            pass.status.end = end;
          }
        }
      });
      this._hallPassesByStudentSubject.next(passes);
    }
  }

  public removePass(passId: number) {
    const passes = this._hallPassesByStudentSubject?.value;

    if (passes) {
      passes.forEach((pass, key) => {
        if (pass.id === passId) {
          passes.delete(key);
        }
      });

      this._hallPassesByStudentSubject.next(passes);
    }
  }

  public addPasses(passes: HallPassWithType.AsObject[]) {
    this._setPasses(passes);
  }

  public async end(pass: HpmDashboardTableItem, typeName: string) {
    const response = await this._hallPassActions.endConfirm(pass, typeName);

    if (response === 'success') {
      this._endPass(pass.id);
    }
  }

  public async showPendingApprovalDialog(
    pass: HpmDashboardTableItem,
    type: IHallPassType,
  ) {
    const response = await this._hallPassActions.pendingApprovalDialogConfirm(
      pass,
      type,
    );

    if (response === SystemAlertCloseEvents.CONFIRM) {
      this._fetchPasses();
    }

    if (response === SystemAlertCloseEvents.CLOSE) {
      this.removePass(pass.id);
    }
  }

  public fetchTypes(): Observable<IHallPassType[]> {
    return this._cachedTypes.get();
  }

  public async initFetchPolling() {
    this._fetchPasses();

    interval(POLL_INTERVAL_MS)
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(() => {
          const currentTime = Date.now();
          const timeSinceLastFetch = currentTime - this._lastFetched;
          return timeSinceLastFetch >= FETCH_INTERVAL_MS;
        }),
      )
      .subscribe(() => {
        this._fetchPasses();
      });
  }

  private _endPass(passId: number) {
    this.updatePass(passId, HallPassStatusEnum.ENDED, new Date());
  }

  private _handleHallPassApprovalEvents() {
    this._realtimeEvents
      .observe([
        RealtimeEventType.HALL_PASS_APPROVED,
        RealtimeEventType.HALL_PASS_DENIED,
        RealtimeEventType.HALL_PASS_APPROVAL_REQUEST,
      ])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(data => {
        const id = data.payload.notification.metadata.hallPass.id;
        const type = data.type;

        if (type === RealtimeEventType.HALL_PASS_APPROVED) {
          this.updatePass(id, HallPassStatusEnum.ACTIVE);
        }

        if (type === RealtimeEventType.HALL_PASS_DENIED) {
          this.removePass(id);
        }

        if (type === RealtimeEventType.HALL_PASS_APPROVAL_REQUEST) {
          this._fetchPasses();

          if (this._soundEnabled) {
            this._audioPlayerService.playAudio(MingaAudio.POSITIVE_PING); // CHANGE THIS WITH THE RIGHT SOUND
          }
        }
      });
  }
}
