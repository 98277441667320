import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import {
  StudentIdImageService,
  StudentIdPresetSize,
} from '@shared/services/student-id-image/StudentIdImage.service';

@Directive({
  exportAs: 'mgStudentPicture',
  selector: '[mgStudentPicture]',
})
export class StudentPictureDirective implements AfterViewInit {
  @Input()
  mgStudentPicture: string;

  constructor(
    private _el: ElementRef,
    private _idImage: StudentIdImageService,
  ) {}

  ngAfterViewInit(): void {
    this._getStudentIdImage();
  }

  private async _getStudentIdImage(): Promise<any> {
    if (!this.mgStudentPicture) return;

    try {
      const path = await this._idImage.getFilePathToStudentIdImage(
        this.mgStudentPicture,
        StudentIdPresetSize.AVATAR,
      );
      this._el.nativeElement.style.backgroundImage = `url(${path})`;
    } catch (e) {
      // suppress image not found error, the rendering logic when this errors out should be handled in the component
    }
  }
}
