<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayout.gt-sm="column"
  fxLayoutGap.lt-md="12px">
  <mg-form-text-input
    *ngIf="canAddNote$ | async"
    [id]="'tools-myclass-input-note-hp'"
    [control]="group.get(HALLPASS_FIELDS.NOTE)"
    [label]="MESSAGES.FORM_LABEL_NOTE">
  </mg-form-text-input>

  <div class="advanced">
    <span
      class="mat-menu-spacer"
      [matMenuTriggerFor]="fields">
      <mg-chip>Advanced</mg-chip>
    </span>
  </div>
</div>

<mat-menu
  class="mat-menu block-assignment-input"
  #fields="matMenu"
  yPosition="above"
  xPosition="before"
  [hasBackdrop]="true">
  <ng-template matMenuContent>
    <div
      class="popup-wrap"
      fxLayout="column"
      fxLayoutGap="12px"
      (click)="$event.stopPropagation()"
      (keydown)="matMenuKeydown($event)">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div>
          <mg-text variant="body-md">
            {{ MESSAGES.FORM_LABEL_PASS_DURATION }}
          </mg-text>
        </div>
        <div>
          <mg-form-text-input
            inputType="number"
            min="1"
            [id]="'tools-myclass-input-duration'"
            [autofocus]="true"
            [control]="group.get(HALLPASS_FIELDS.DURATION)">
          </mg-form-text-input>
        </div>
      </div>

      <div>
        <mg-form-select
          mgTeacherSearch
          [excludeCurrentUser]="true"
          appendTo="body"
          [placeholder]="MESSAGES.FORM_LABEL_APPROVED_BY"
          [closeOnSelect]="true"
          [multiple]="false"
          [searchable]="true"
          [hideSelected]="false"
          [floatingLabel]="false"
          [control]="approveByControl"
          [canGrantHallPass]="true"
          [isClearable]="true"
          returnMode="full"
          [disabled]="disabledApprovedBy$ | async"
          (selectionChanged)="assignerChange($event)">
        </mg-form-select>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="4px">
        <mg-form-checkbox
          [id]="'tools-myclass-click-check-sche'"
          [checked]="scheduleForLater.value"
          [disabled]="disabledScheduledLater$ | async"
          (changed)="scheduleForLater.setValue($event)">
        </mg-form-checkbox>
        <mg-text
          variant="label-md"
          [color]="
            (disabledScheduledLater$ | async) ? 'on-disabled-bg' : 'surface'
          ">
          {{ MESSAGES.FORM_LABEL_SCHEDULE_LATER }}
        </mg-text>
      </div>
      <div
        *ngIf="scheduleForLater.value"
        fxLayout="column"
        fxLayoutGap="12px">
        <mg-form-date
          [label]="MESSAGES.FORM_LABEL_SCHEDULE_DATE"
          [id]="'tools-myclass-input-due-date'"
          [minDate]="today"
          [control]="group.get(HALLPASS_FIELDS.DATE)"></mg-form-date>
        <div>
          <mg-form-text-input
            [externalError]="
              group.dirty && group.getError('invalidDateTimeInPast')
            "
            [label]="MESSAGES.FORM_LABEL_SCHEDULE_TIME"
            inputType="time"
            [step]="60"
            [control]="group.get(HALLPASS_FIELDS.TIME)">
          </mg-form-text-input>
          <mg-form-error
            *ngIf="group.dirty && group.getError('invalidDateTimeInPast')">
            {{ MESSAGES.FORM_INVALID_TIME }}
          </mg-form-error>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>
