import { Validators } from '@angular/forms';

import {
  Assignment,
  AssignmentType,
  CategoryType,
  FormState,
} from '../types/tt-my-class.types';

export enum FORM_FIELDS {
  CATEGORY = 'category',
  SELECTED_ACTION = 'selectedAction',
  SELECTED_STUDENTS = 'selectedStudents',
  LIST_FILTER = 'listFilter',
  SORT_FIRST_NAME_FILTER = 'sortFirstNameFilter',
  HIDE_PICTURE_FILTER = 'hidePictureFilter',
  SEARCH_TEXT_FILTER = 'searchTextFilter',
  PAGE_LAYOUT = 'pageLayout',
  ENABLED_SOUND = 'enabledSound',
}

export const FORM = () => ({
  [FORM_FIELDS.CATEGORY]: [CategoryType.MOST_USED, [Validators.required]],
  [FORM_FIELDS.SELECTED_ACTION]: [null, [Validators.required]],
  [FORM_FIELDS.SELECTED_STUDENTS]: [[], [Validators.required]],
  [FORM_FIELDS.LIST_FILTER]: [[]],
  [FORM_FIELDS.SORT_FIRST_NAME_FILTER]: [false],
  [FORM_FIELDS.HIDE_PICTURE_FILTER]: [false],
  [FORM_FIELDS.SEARCH_TEXT_FILTER]: [''],
  [FORM_FIELDS.PAGE_LAYOUT]: ['grid'],
  [FORM_FIELDS.ENABLED_SOUND]: [false],
});

export enum HALLPASS_FIELDS {
  NOTE = 'note',
  DURATION = 'duration',
  DATE = 'date',
  TIME = 'time',
  APPROVED_BY = 'approvedBy',
}
export const HALLPASS_GROUP = () => ({
  [HALLPASS_FIELDS.NOTE]: [null, [Validators.maxLength(180)]],
  [HALLPASS_FIELDS.DURATION]: [null, [Validators.required]],
  [HALLPASS_FIELDS.DATE]: [null],
  [HALLPASS_FIELDS.TIME]: [null],
  [HALLPASS_FIELDS.APPROVED_BY]: [null],
});

export enum CONSEQUENCE_FIELDS {
  NOTE = 'note',
  DATE = 'date',
}
export const CONSEQUENCE_GROUP = () => ({
  [CONSEQUENCE_FIELDS.NOTE]: [null, [Validators.maxLength(180)]],
  [CONSEQUENCE_FIELDS.DATE]: [null],
});

export enum BEHAVIOR_FIELDS {
  NOTE = 'note',
}
export const BEHAVIOR_GROUP = () => ({
  [BEHAVIOR_FIELDS.NOTE]: [null, [Validators.maxLength(180)]],
});

export const ERROR_MESSAGES = {
  [FORM_FIELDS.SELECTED_ACTION]: 'You must select an action',
  [FORM_FIELDS.SELECTED_STUDENTS]: 'You must select a student',
  [AssignmentType.HALLPASS]: 'Scheduled time is not valid',
};

export type SavedPreferences = {
  [FORM_FIELDS.LIST_FILTER]: number[];
  [FORM_FIELDS.CATEGORY]: CategoryType;
  [FORM_FIELDS.SELECTED_ACTION]: Assignment;
  // Below are ui preferences
  [FORM_FIELDS.HIDE_PICTURE_FILTER]: boolean;
  [FORM_FIELDS.SORT_FIRST_NAME_FILTER]: boolean;
  [FORM_FIELDS.PAGE_LAYOUT]: 'list' | 'grid';
  [FORM_FIELDS.ENABLED_SOUND]: boolean;
};

export const LOCKED_STATES: FormState[] = [
  'loading',
  'submitting',
  'randomizing',
];

export const MOST_USED_COUNT = 4;

export enum MyClassMessages {
  PROGRESS_NEXT = 'Next',

  MOBILE_ASSIGNMENT_TITLE = 'Select type',

  NO_USERS_FOUND_TITLE = 'No users found',
  NO_USERS_FOUND_SUBTITLE = 'You need to add users to this list',

  NO_USER_LIST_SELECTED_TITLE = 'No user list selected',
  NO_USER_LIST_SELECTED_SUBTITLE = 'Select a user list to display its roster',

  NO_USER_LIST_FOUND_TITLE = 'No user list found',
  NO_USER_LIST_FOUND_SUBTITLE = 'You need to have access to a user list in order to display its roster',

  EMPTY_LIST_ACTION_BUTTON = 'Create a user list',

  FORM_LABEL_LIST = 'User list',
  FORM_LABEL_TEXT_SEARCH = 'Search all students',
  FORM_LABEL_UNSELECT_ALL = 'Unselect all',
  FORM_LABEL_SELECT_ALL = 'Select all',
  FORM_LABEL_SELECT_RANDOM = 'Select random',
  FORM_LABEL_SELECT_RANDOMIZING = 'Randomizing',
  FORM_LABEL_CATEGORY = 'Category',
  FORM_LABEL_NOTE = 'Note to student (optional)',
  FORM_LABEL_PASS_DURATION = 'Pass duration (mins)',
  FORM_LABEL_APPROVED_BY = 'Approved by (optional)',
  FORM_LABEL_SCHEDULE_LATER = 'Schedule for later (optional)',
  FORM_LABEL_SCHEDULE_DATE = 'Scheduled date',
  FORM_LABEL_SCHEDULE_TIME = 'Scheduled time',
  FORM_INVALID_TIME = 'This is not a valid time',
  FORM_LABEL_DUE_DATE = 'Due date',
  FORM_LABEL_SEARCH_ACTION = 'Search action',

  HEADER_IN_CLASS = 'My Class',
  HEADER_OUT_OF_CLASS = 'Active Hall Pass',
  HEADER_ENTIRE_SCHOOL = 'Other Students',

  SETTINGS_PAGE_LAYOUT = 'Page layout',
  SETTINGS_SORT_NAME = 'Sort by last name',
  SETTINGS_DISPLAY_AVATAR = 'Display student pictures',
  SETTINGS_SOUND = 'Enable sound notifications',
  SETTINGS_SOUND_TOOLTIP = 'Enable an audible notification when a pass is overdue, a student creates or when a pass is requested to you',
  SETTINGS_MOST_USED_LIST = 'Show most used per list',
  SETTINGS_MOST_USED_LIST_TOOLTIP = 'Show most used per list tooltip',

  ASSIGN_BTN = 'Assign',
  NEXT_BTN = 'Next',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',

  FETCH_ACTIONS_ERROR = 'There was an error fetching your action types, please try again',
  FETCH_STUDENTS_ERROR = 'There was an error fetching your student list, please try again.',
  FETCH_HALL_PASSES_ERROR = 'There was an error fetching hall passes, please try again',

  ASSIGNING_ACTION_ERROR = "There's was an issue assigning action, please try again",
  FETCHING_LISTS_ERROR = 'There was an error fetching your user lists, please try again',

  MOST_USED_EMPTY_TITLE = 'Nothing here yet!',
  MOST_USED_EMPTY_SUBTITLE = 'Most frequently assigned types will be shown here',

  SIDEBAR_SHOW_MORE_BTN = 'Show more',
  SIDEBAR_SHOW_LESS_BTN = 'Show less',

  SELECT_CATEGORY_MOST_USED = 'Most used',
  SELECT_CATEGORY_MOST_USED_BY_LIST = 'Most used with list',
  SELECT_CATEGORY_CONSEQUENCE = 'Consequence',
  SELECT_CATEGORY_GUIDANCE = 'Guidance',
  SELECT_CATEGORY_PRAISE = 'Praise',
  SELECT_CATEGORY_HALLPASS = 'Hall pass',
  SELECT_CATEGORY_COMMUNICATION = 'Communication',
}
