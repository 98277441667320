<ng-container *ngIf="mobileLayout">
  <mg-form-sheet-grouped-select
    [id]="id"
    [value]="initialOptions$ | async"
    [disabled]="disabled"
    [options]="options"
    [done$]="done$"
    [multiple]="multiple"
    [lazyLoad]="true"
    [clearSelection]="clearSelection"
    (selectChange)="selectChangeHandler($event)">
  </mg-form-sheet-grouped-select>
</ng-container>

<ng-container *ngIf="!mobileLayout">
  <mg-form-grouped-select
    #formSelectComponent
    *ngIf="dataLoaded$ | async; else loadingTemplate"
    [appendTo]="appendTo"
    [id]="id"
    [value]="initialOptions$ | async"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [options]="options"
    [multiple]="multiple"
    [lazyLoad]="true"
    [disableWrapping]="disableWrapping"
    (selectChange)="selectChangeHandler($event)">
  </mg-form-grouped-select>

  <ng-template #loadingTemplate>
    <mg-form-grouped-select
      [disabled]="true"
      [loading]="true"
      [placeholder]="placeholder">
    </mg-form-grouped-select>
  </ng-template>
</ng-container>
