import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import * as day from 'dayjs';
import Color from 'color';
import {
  BehaviorSubject,
  combineLatest,
  interval,
  merge,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { HallPassStatusEnum, IHallPass } from 'minga/libraries/domain';
import { MingaPermission } from 'minga/libraries/domain';
import { mingaSettingTypes } from 'minga/libraries/util';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { makePassStatusDetails } from '@modules/hallpass-manager';

import { MediaService } from '@shared/services/media';

@Component({
  selector: 'mg-view-id-hallpass',
  templateUrl: './view-id-hallpass.component.html',
  styleUrls: ['./view-id-hallpass.component.scss'],
})
export class ViewIdHallpassComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Children

  @ViewChild('container', { static: false }) container: ElementRef;

  // Observables

  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private readonly _endPassSubject = new ReplaySubject<void>(1);

  private readonly _showPassSubject = new BehaviorSubject<boolean>(false);
  public readonly showPass$ = this._showPassSubject.asObservable();

  public endPassPermissions$ = combineLatest([
    this._permissions.observePermission(MingaPermission.HALL_PASS_MANAGE),
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.PASS_STUDENTS_END_PASSES_FROM_MOBILE,
    ),
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([hallPassManager, mobileEndPassSetting]) => {
      const canEndPass =
        hallPassManager ||
        (this.activePass.hallPassType?.studentCanEndPass ??
          this.endPassSetting);

      const canEndPassOnMobile =
        this.activePass.hallPassType?.canEndOnMobile ??
        (mobileEndPassSetting as boolean);

      return { canEndPass, canEndPassOnMobile };
    }),
  );

  public remainingTime$: Observable<number>;

  // Inputs

  @Input() hash: string;
  @Input() activePass: IHallPass;
  @Input() endPassSetting: boolean;

  // Constructor

  constructor(
    public media: MediaService,
    private _hallPass: HallPassService,
    private _permissions: PermissionsService,
    private _mingaSettings: MingaSettingsService,
  ) {}

  // Lifecycle Hooks

  ngOnInit(): void {
    this._initTimer();
  }

  ngAfterViewInit(): void {
    this._setHighContrastFontColorProperty(this.activePass.hallPassType.color);
    this._setBackgroundColorProperty(this.activePass.hallPassType.color);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._endPassSubject.complete();
  }

  // Public Methods

  public async endPass() {
    // weird name, expired passes are what we classify as overdue... this fully ends the pass
    await this._hallPass.expireHallPass(this.activePass.id);

    this._showPassSubject.next(false);
    this._endPassSubject.next();
    this._endPassSubject.complete();
  }

  // Private Methods

  private _initTimer() {
    this.remainingTime$ = interval(1000).pipe(
      takeUntil(merge(this._destroyedSubject, this._endPassSubject)),
      map(() => {
        if (!this.activePass.expiredDate && !this.activePass.startDate) return;

        const now = day();
        const expireTime = day(this.activePass.expiredDate); // when pass goes into overdue
        const endTime = day(this.activePass.endDate); // when pass is past overdue and fully ended

        // pass expired (goes into overdue)
        if (now.isAfter(expireTime)) {
          // overdue color palette
          this._setFontColorProperty('#d02a2a');
          this._setBackgroundColorProperty('#fbebeb');
        } else {
        }
        const details = makePassStatusDetails(
          now,
          this.activePass.startDate,
          this.activePass.expiredDate,
          this.activePass.endDate,
          !this.activePass.approvalDate,
          null,
        );
        const status = details.state;
        // only active or overdue passes should show up on the id.
        const isActive =
          status === HallPassStatusEnum.ACTIVE ||
          status === HallPassStatusEnum.OVERDUE;

        // pass ends (fully ended either manually or though timeout)
        if (!isActive) {
          this._showPassSubject.next(false);
          this._endPassSubject.next();
          this._endPassSubject.complete();
        } else {
          this._showPassSubject.next(true);
        }

        // calculate diference from now to expire time
        const timeDiff = expireTime.diff(now);
        const timeRemaining = Math.floor(day.duration(timeDiff).asSeconds());

        return timeRemaining;
      }),
    );
  }

  private _setHighContrastFontColorProperty(backgroundColor: string) {
    if (!this.container) return;

    // Change color depending on background color for best contrast
    const color = Color(backgroundColor);

    // This contrast adheres to WCAG contrast ratio guidelines
    const fontColor = color.isLight() ? '#003366' : '#ffffff';

    this.container.nativeElement.style.setProperty(
      '--dynamic-font-color',
      fontColor,
    );
  }

  private _setFontColorProperty(fontColor: string) {
    if (!this.container) return;

    this.container.nativeElement.style.setProperty(
      '--dynamic-font-color',
      fontColor,
    );
  }

  private _setBackgroundColorProperty(backgroundColor: string) {
    if (!this.container) return;

    this.container.nativeElement.style.setProperty(
      '--dynamic-background-color',
      backgroundColor,
    );
  }
}
