import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgAvatarModule } from 'src/app/elements/MgAvatar';
import { MgIconModule } from 'src/app/icon';
import { MgSpinnerModule } from 'src/app/spinner';

import { HpmDashboardPipesModule } from '@modules/hallpass-manager/components/hpm-dashboard/pipes/hpm-dashboard-pipes.module';
import { PeopleUserListEditModule } from '@modules/people/components/people-userlists/component/people-userslists-edit/people-userlists-edit.module';
import { PeopleUserListsPageStore } from '@modules/people/components/people-userlists/services';
import { SaSharedModule } from '@modules/selection-assigner/components/sa-shared/sa-shared.module';

import { CollapsibleModule } from '@shared/components/collapsible';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { LayoutHeaderModule } from '@shared/components/layout-header';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { CommonUIModules } from '@shared/modules';
import { ArrayPipesModule } from '@shared/pipes';
import { SlugifyPipeModule } from '@shared/pipes/slugify/slugify-pipe.module';
import { HallPassService } from '@shared/services/hall-pass';

import { TtAssignmentFooterComponent } from './components/tt-assignment-footer/tt-assignment-footer.component';
import { TtBehaviorFieldsComponent } from './components/tt-behavior-fields/tt-behavior-fields.component';
import { TtConsequenceFieldsComponent } from './components/tt-consequence-fields/tt-consequence-fields.component';
import { TtFiltersComponent } from './components/tt-filters/tt-filters.component';
import { TtHallpassFieldsComponent } from './components/tt-hallpass-fields/tt-hallpass-fields.component';
import { TtPillComponent } from './components/tt-pill/tt-pill.component';
import { TtSidebarComponent } from './components/tt-sidebar/tt-sidebar.component';
import { TtStudentCardComponent } from './components/tt-student-card/tt-student-card.component';
import { TtStudentListItemComponent } from './components/tt-student-list-item/tt-student-list-item.component';
import { StudentSortPipe } from './pipes/student-sort-pipe';
import { TtMyClassComponent } from './tt-my-class.component';

@NgModule({
  imports: [
    // Common modules,
    CommonUIModules,

    CommonModule,
    SystemAlertModule,
    TextModule,
    NgSelectModule,
    MgIconModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    SaSharedModule,
    GenericModule,
    MgEmptyStateModule,
    MgSpinnerModule,
    CollapsibleModule,
    ArrayPipesModule,
    MatMenuModule,
    MgAvatarModule,
    RouterModule,
    MatRippleModule,
    MatTooltipModule,
    HpmDashboardPipesModule,
    LayoutHeaderModule,
    PeopleUserListEditModule,
    SlugifyPipeModule,
    UserListFilterModule,
  ],
  declarations: [
    TtMyClassComponent,
    TtHallpassFieldsComponent,
    TtBehaviorFieldsComponent,
    TtConsequenceFieldsComponent,
    TtStudentCardComponent,
    TtPillComponent,
    TtSidebarComponent,
    StudentSortPipe,
    TtAssignmentFooterComponent,
    TtStudentListItemComponent,
    TtFiltersComponent,
  ],
  exports: [],
  providers: [HallPassService, PeopleUserListsPageStore],
})
export class TtMyClassModule {}
