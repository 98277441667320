<mg-portal-layout-header
  *ngIf="
    (mediaService.isMobileView$ | async) === true &&
    (mobileProgress$ | async) === 'assign'
  ">
  <div
    class="mobile-assigment-header"
    fxLayout="row"
    fxLayoutAlign="center center">
    <mg-text
      variant="header-xl-secondary"
      textAlign="center"
      >{{ MESSAGES.MOBILE_ASSIGNMENT_TITLE }}</mg-text
    >
    <mg-btn
      size="large"
      variant="icon-secondary"
      iconSet="minga"
      icon="mg-cross"
      (pressed)="setMobileProgress('select')"></mg-btn>
  </div>
</mg-portal-layout-header>

<mg-portal-layout-footer *ngIf="mediaService.isMobileView$ | async">
  <div
    *ngIf="
      (mobileProgress$ | async) === 'select';
      else assignmentFooterTemplate
    "
    class="mobile-btn-wrap">
    <mg-btn
      [disabled]="form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length === 0"
      [wide]="true"
      (pressed)="setMobileProgress('assign')">
      {{ MESSAGES.PROGRESS_NEXT }}
    </mg-btn>
  </div>
</mg-portal-layout-footer>

<section
  class="my-class full-width-layout"
  fxLayout="column"
  fxLayoutGap="16px"
  fxLayout.gt-sm="row">
  <div
    *ngIf="(formState$ | async) === 'loading'"
    class="my-class-loading">
    <mg-spinner></mg-spinner>
  </div>

  <div
    *ngIf="(formState$ | async) !== 'loading'"
    class="my-class-main"
    [ngClass]="'layout-' + form.get(FORM_FIELDS.PAGE_LAYOUT).value"
    fxLayout="column"
    fxLayoutGap="8px">
    <div
      class="my-class-main-filters"
      [class.desktop-filters]="(mediaService.isMobileView$ | async) === false">
      <ng-container *ngIf="mediaService.isMobileView$ | async">
        <mg-collapsible
          [style.display]="
            (mobileProgress$ | async) === 'assign' ? 'none' : 'block'
          "
          [collapseText]="MESSAGES.HIDE_FILTERS"
          [expandText]="MESSAGES.SHOW_FILTERS">
          <mg-tt-filters
            *ngIf="filteredStudentList$ | async as filteredStudentList"
            [form]="form"
            [searchingAllStudents]="searchingAllStudents$ | async"
            [formState]="formState$ | async"
            [totalNumberOfStudents]="filteredStudentList?.length"
            [selectedNumberOfStudents]="
              form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length
            "
            (userListChanged)="userListChanged($event)"
            (selectRandom)="selectRandom(filteredStudentList)"
            (toggleSelectAll)="
              toggleSelectAll(filteredStudentList)
            "></mg-tt-filters>
        </mg-collapsible>
      </ng-container>
      <ng-container *ngIf="(mediaService.isMobileView$ | async) === false">
        <mg-tt-filters
          *ngIf="filteredStudentList$ | async as filteredStudentList"
          [form]="form"
          [searchingAllStudents]="searchingAllStudents$ | async"
          [formState]="formState$ | async"
          [totalNumberOfStudents]="filteredStudentList?.length"
          [selectedNumberOfStudents]="
            form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length
          "
          (userListChanged)="userListChanged($event)"
          (selectRandom)="selectRandom(filteredStudentList)"
          (toggleSelectAll)="
            toggleSelectAll(filteredStudentList)
          "></mg-tt-filters>
      </ng-container>
    </div>
    <div class="my-class-main-students">
      <ng-container
        [ngTemplateOutlet]="mainWrapTemplate"
        [ngTemplateOutletContext]="{
          studentsWithHallpass: studentsWithHallpass$ | async,
          studentsWithoutHallpass: studentsWithoutHallpass$ | async,
          studentsFromEntireSchool: studentsFromEntireSchool$ | async,
          hallPassesByStudent: hallPassesByStudent$ | async
        }"></ng-container>
    </div>
  </div>

  <ng-container
    *ngIf="mediaService.isMobileView$ | async; else desktopSidebarTemplate"
    [ngTemplateOutlet]="mobileSidebarTemplate"></ng-container>
</section>

<ng-template
  #mainWrapTemplate
  let-studentsWithHallpass="studentsWithHallpass"
  let-studentsWithoutHallpass="studentsWithoutHallpass"
  let-studentsFromEntireSchool="studentsFromEntireSchool"
  let-hallPassesByStudent="hallPassesByStudent">
  <ng-container *ngIf="mediaService.isMobileView$ | async">
    <!-- just visually hiding otherwise triggers a we lose some state -->
    <div
      [style.display]="
        (mobileProgress$ | async) === 'assign' ? 'none' : 'block'
      "
      class="my-class-main-mobile">
      <ng-container
        [ngTemplateOutlet]="mainTemplate"
        [ngTemplateOutletContext]="{
          studentsWithHallpass: studentsWithHallpass,
          studentsWithoutHallpass: studentsWithoutHallpass,
          studentsFromEntireSchool: studentsFromEntireSchool,
          hallPassesByStudent: hallPassesByStudent
        }"></ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="(mediaService.isMobileView$ | async) === false"
    [ngTemplateOutlet]="mainTemplate"
    [ngTemplateOutletContext]="{
      studentsWithHallpass: studentsWithHallpass,
      studentsWithoutHallpass: studentsWithoutHallpass,
      studentsFromEntireSchool: studentsFromEntireSchool,
      hallPassesByStudent: hallPassesByStudent
    }"></ng-container>
</ng-template>

<ng-template
  #mainTemplate
  let-studentsWithHallpass="studentsWithHallpass"
  let-studentsWithoutHallpass="studentsWithoutHallpass"
  let-studentsFromEntireSchool="studentsFromEntireSchool"
  let-hallPassesByStudent="hallPassesByStudent">
  <div
    *ngIf="fetchingStudents$ | async; else studentCardsTemplate"
    class="my-class-main-loading">
    <mg-spinner></mg-spinner>
  </div>

  <ng-template #studentCardsTemplate>
    <ng-container
      *ngIf="
        form.get(FORM_FIELDS.LIST_FILTER).value.length > 0;
        else noListsTemplate
      ">
      <ng-container
        *ngIf="
          studentsWithHallpass?.length > 0 &&
          studentsFromEntireSchool?.length === 0
        ">
        <div class="group-header tw-flex tw-flex-row tw-mb-1">
          <mg-icon>mg-away</mg-icon>
          <mg-text variant="header-md-secondary">
            {{ MESSAGES.HEADER_OUT_OF_CLASS }}
          </mg-text>
        </div>

        <ng-container
          [ngTemplateOutlet]="studentListTemplate"
          [ngTemplateOutletContext]="{
            students: studentsWithHallpass,
            hallPassesByStudent: hallPassesByStudent,
            pastAssignments: pastAssignments$ | async,
            canViewProfile: canViewProfile$ | async
          }">
        </ng-container>
      </ng-container>
      <div class="group-header tw-flex tw-flex-row tw-mb-1">
        <mg-icon>mg-sit</mg-icon>
        <mg-text variant="header-md-secondary">
          {{ MESSAGES.HEADER_IN_CLASS }}
        </mg-text>
      </div>

      <ng-container
        *ngIf="studentsWithoutHallpass?.length > 0; else noUsersTemplate">
        <ng-container
          [ngTemplateOutlet]="studentListTemplate"
          [ngTemplateOutletContext]="{
            students: studentsWithoutHallpass,
            hallPassesByStudent: hallPassesByStudent,
            pastAssignments: pastAssignments$ | async,
            canViewProfile: canViewProfile$ | async
          }">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="studentsFromEntireSchool?.length > 0">
        <div class="group-header tw-flex tw-flex-row tw-mb-1">
          <mg-icon>mg-away</mg-icon>
          <mg-text variant="header-md-secondary">
            {{ MESSAGES.HEADER_ENTIRE_SCHOOL }}
          </mg-text>
        </div>

        <ng-container
          [ngTemplateOutlet]="studentListTemplate"
          [ngTemplateOutletContext]="{
            students: studentsFromEntireSchool,
            hallPassesByStudent: hallPassesByStudent,
            pastAssignments: pastAssignments$ | async,
            canViewProfile: false
          }">
        </ng-container>
      </ng-container>

      <ng-template #noUsersTemplate>
        <mg-empty-state
          class="my-class-main-empty-state"
          [title]="MESSAGES.NO_USERS_FOUND_TITLE"
          [subtitle]="MESSAGES.NO_USERS_FOUND_SUBTITLE"></mg-empty-state>
      </ng-template>
    </ng-container>

    <ng-template #noListsTemplate>
      <mg-empty-state
        *ngIf="form.get(FORM_FIELDS.LIST_FILTER).value.length === 0"
        class="my-class-main-empty-state"
        [title]="
          (listOptions$ | async)?.length > 0
            ? MESSAGES.NO_USER_LIST_SELECTED_TITLE
            : MESSAGES.NO_USER_LIST_FOUND_TITLE
        "
        [subtitle]="
          (listOptions$ | async)?.length > 0
            ? MESSAGES.NO_USER_LIST_SELECTED_SUBTITLE
            : MESSAGES.NO_USER_LIST_FOUND_SUBTITLE
        "
        [actionButtonLabel]="MESSAGES.EMPTY_LIST_ACTION_BUTTON"
        (handleAction)="createUserList()"></mg-empty-state>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template
  #studentListTemplate
  let-students="students"
  let-hallPassesByStudent="hallPassesByStudent"
  let-pastAssignments="pastAssignments"
  let-canViewProfile="canViewProfile">
  <div
    class="my-class-student-cards tw-mb-4"
    [ngClass]="{
      'no-avatar': form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value
    }">
    <div
      #studentCard
      *ngFor="
        let student of students
          | studentSort: form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value;
        trackBy: trackByFn
      "
      [attr.data-hash]="student.personHash">
      <mg-tt-student-card
        *ngIf="form.get(FORM_FIELDS.PAGE_LAYOUT).value === 'grid'"
        [canViewProfile]="canViewProfile"
        [authHash]="authHash$ | async"
        [selected]="
          form.get(FORM_FIELDS.SELECTED_STUDENTS).value
            | includes: student.personHash
        "
        [showPulse]="(randomizer$ | async) === student.personHash"
        [student]="student"
        [hidePicture]="form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value"
        [firstNameFirst]="form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value"
        [pastAssignments]="pastAssignments?.get(student.personHash) || []"
        [hallPass]="hallPassesByStudent?.get(student.personHash)"
        (toggleSelected)="toggleSelected($event, student)"
        (hallPassChange)="onHallPassChange($event)"></mg-tt-student-card>

      <mg-tt-student-list-item
        *ngIf="form.get(FORM_FIELDS.PAGE_LAYOUT).value === 'list'"
        [canViewProfile]="canViewProfile"
        [authHash]="authHash$ | async"
        [selected]="
          form.get(FORM_FIELDS.SELECTED_STUDENTS).value
            | includes: student.personHash
        "
        [showPulse]="(randomizer$ | async) === student.personHash"
        [student]="student"
        [hidePicture]="form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value"
        [firstNameFirst]="form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value"
        [pastAssignments]="pastAssignments?.get(student.personHash) || []"
        [hallPass]="hallPassesByStudent?.get(student.personHash)"
        (toggleSelected)="toggleSelected($event, student)"
        (hallPassChange)="onHallPassChange($event)"></mg-tt-student-list-item>
    </div>
  </div>
</ng-template>

<ng-template #filtersTemplate> </ng-template>

<ng-template #sidebarTemplate>
  <mg-my-class-sidebar
    *ngIf="(formState$ | async) !== 'loading'"
    [actionGroups]="actionGroups$ | async"
    [form]="form"
    (assign)="assign()">
    <ng-container
      *ngIf="(mediaService.isMobileView$ | async) === false"
      [ngTemplateOutlet]="assignmentFooterTemplate">
    </ng-container>
  </mg-my-class-sidebar>
</ng-template>

<ng-template #mobileSidebarTemplate>
  <div
    *ngIf="(mobileProgress$ | async) === 'assign'"
    class="my-class-sidebar-mobile">
    <ng-container *ngTemplateOutlet="sidebarTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #desktopSidebarTemplate>
  <div class="my-class-sidebar">
    <ng-container *ngTemplateOutlet="sidebarTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #assignmentFooterTemplate>
  <mg-tt-assignment-footer
    [form]="form"
    [formState]="formState$ | async"
    [formErrors]="formErrors$ | async"
    [assignSuccess$]="assignSuccess$"
    (assign)="assign()"></mg-tt-assignment-footer>
</ng-template>
