import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IBellSchedule } from 'minga/libraries/domain';
import { Period } from 'minga/libraries/domain';
import { Term } from 'minga/libraries/domain';
import { MgValidators } from 'src/app/input/validators';
import { RootService } from 'src/app/minimal/services/RootService';
import { scrollIntoView } from 'src/app/util/scroll-into-view';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { Weekday } from '@shared/components/weekday-toggle';
import { BsPeriodsService } from '@shared/services/bell-schedule/bs-periods.service';
import { BsSchedulesService } from '@shared/services/bell-schedule/bs-schedules.service';
import { BsTermsService } from '@shared/services/bell-schedule/bs-terms.service';

import {
  createForm,
  getData,
  SCHEDULE_FORM_FIELDS,
  SCHEDULE_PERIOD_FIELDS,
  SCHEDULES_MESSAGES,
  setForm,
} from '../../constants/mm-bs-schedules.constants';
import {
  BsScheduleEditData,
  BsScheduleEditResponse,
} from '../../types/mm-bell-schedule.types';
import { noOverlappingDaysByTermValidator } from '../../validators/noOverlappingDaysByTerm.validator';
import {
  overlappingPeriodsValidator,
  PeriodOverlapWarnings,
} from '../../validators/noOverlappingPeriods.validator';

@Component({
  selector: 'mg-mm-bs-schedules-edit',
  templateUrl: './mm-bs-schedules-edit.component.html',
  styleUrls: ['./mm-bs-schedules-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmBsSchedulesEditComponent
  extends CrudFormBase<IBellSchedule>
  implements OnInit, OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(1);

  private _loadingData = new BehaviorSubject<boolean>(true);
  public readonly loadingData$ = this._loadingData.asObservable();

  public MESSAGES = SCHEDULES_MESSAGES;
  public FORM_FIELDS = SCHEDULE_FORM_FIELDS;
  public PERIOD_FIELDS = SCHEDULE_PERIOD_FIELDS;
  public readonly form = this._fb.group({
    ...createForm(),
    [SCHEDULE_FORM_FIELDS.PERIODS]: this._fb.array([], {}),
  });

  private _invalidDaysSubject = new BehaviorSubject<Weekday[]>([]);
  public invalidDays$ = this._invalidDaysSubject.asObservable();

  private _schedules: IBellSchedule[] = [];

  private _termsSubject = new BehaviorSubject<Term[]>([]);
  public termsOptions$ = this._termsSubject.pipe(
    map(terms => {
      return terms.map(term => ({
        value: term.id,
        label: term.title,
      }));
    }),
  );

  private _usedShortCodesSubject = new BehaviorSubject<string[]>([]);
  public usedShortCodes$ = this._usedShortCodesSubject.asObservable();

  private _periodsSubject = new BehaviorSubject<Period[]>([]);
  public allShortCodes$ = this._periodsSubject.pipe(
    map(periods => {
      return periods.map(p => ({
        value: p.shortCode,
        label: p.shortCode,
      }));
    }),
  );

  public showCodeOptions$ = combineLatest([
    this.allShortCodes$,
    this.usedShortCodes$,
  ]).pipe(
    map(([options, usedShortCodes]) => {
      return options.map(option => {
        return {
          ...option,
          disabled: usedShortCodes.includes(option.value),
        };
      });
    }),
  );

  private _periodWarnings = new BehaviorSubject<PeriodOverlapWarnings>({});
  public periodWarnings$ = this._periodWarnings.asObservable();

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BsScheduleEditData,
    private _modalRef: ModalOverlayRef<
      BsScheduleEditResponse,
      BsScheduleEditData
    >,
    private _fb: FormBuilder,
    private _systemAlertModal: SystemAlertModalService,
    public rootService: RootService,
    private _snackBarService: SystemAlertSnackBarService,
    private _bsSchedulesService: BsSchedulesService,
    private _bsTermsService: BsTermsService,
    private _bsPeriodsService: BsPeriodsService,
  ) {
    super({
      id: dialogData?.id,
      get: async id => {
        return this._bsSchedulesService.get(id);
      },
      create: data => {
        return this._bsSchedulesService.upsert(data);
      },
      update: data => {
        return this._bsSchedulesService.upsert(data as IBellSchedule);
      },
      delete: async data => {
        await this._bsSchedulesService.delete(data.id);
        return data;
      },
      onSetForm: data => {
        setForm(data as any, this.form);

        this._initializePeriods(data);
      },
      onValidate: data => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const termIds = this.form.get(SCHEDULE_FORM_FIELDS.TERMS).value;
        const terms = this._termsSubject.value
          .filter(t => {
            return termIds.includes(t.id);
          })
          .map(t => ({ id: t.id, title: t.title }));

        const mapped = {
          ...getData(data as any, this.form),
          periods: this.form.get(SCHEDULE_FORM_FIELDS.PERIODS).value,
          terms,
        };

        return mapped as any;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: SCHEDULES_MESSAGES.DELETE_CONFIRM_DISCARD_TITLE,
          message: SCHEDULES_MESSAGES.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: SCHEDULES_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: SCHEDULES_MESSAGES.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: SCHEDULES_MESSAGES.DELETE_CONFIRM_TITLE,
          message: SCHEDULES_MESSAGES.DELETE_CONFIRM_DESC,
          closeBtn: SCHEDULES_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: SCHEDULES_MESSAGES.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.init();
    this._getInitialData();
    this._setShortCodeListener();

    this.periodWarnings$ = this.periods.valueChanges.pipe(
      map(periods => {
        const warnings = overlappingPeriodsValidator(this.periods, true);
        return warnings || {};
      }),
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public updateActiveDays(days: string[]) {
    this.form.get(SCHEDULE_FORM_FIELDS.DAYS).setValue(days);
  }

  get periods() {
    return this.form.get(SCHEDULE_FORM_FIELDS.PERIODS) as FormArray;
  }

  public addPeriod() {
    const currentTotal = this.periods.length;

    const lastPeriod = this.periods.at(currentTotal - 1);
    const lastEndTime = lastPeriod.get(SCHEDULE_PERIOD_FIELDS.END_TIME).value;

    this.periods.push(
      this._createPeriod({
        periodName: `Period ${currentTotal + 1}`,
        ...this._getPeriodDefaultTimeRange(lastEndTime),
      }),
    );
  }

  public deletePeriod(index: number) {
    this.periods.removeAt(index);
  }

  public addShortCode(code: string) {
    return { label: code, value: code };
  }

  private async _getInitialData() {
    try {
      this._loadingData.next(true);

      const [schedules, terms, periods] = await Promise.all([
        this._bsSchedulesService.fetchAll(),
        this._bsTermsService.fetchAll(),
        this._bsPeriodsService.fetchAll(),
      ]);

      this._schedules = schedules;
      this._termsSubject.next(terms);
      this._periodsSubject.next(periods);

      const excludeCurrentSchedule = this._schedules.filter(
        s => s.id !== this.dialogData?.id,
      );
      this.form.setValidators(
        noOverlappingDaysByTermValidator(excludeCurrentSchedule),
      );
      this.form.updateValueAndValidity();
    } catch (e) {
      this._snackBarService.error(SCHEDULES_MESSAGES.ERROR_LOADING_DATA);
    } finally {
      this._loadingData.next(false);
    }
  }

  private _createPeriod({
    id = null,
    shortCode = null,
    periodName = '',
    startTime = null,
    endTime = null,
  } = {}): FormGroup {
    return this._fb.group(
      {
        [SCHEDULE_PERIOD_FIELDS.ID]: [id],
        [SCHEDULE_PERIOD_FIELDS.SHORT_CODE]: [shortCode, [Validators.required]],
        [SCHEDULE_PERIOD_FIELDS.NAME]: [periodName, [Validators.required]],
        [SCHEDULE_PERIOD_FIELDS.START_TIME]: [startTime, [Validators.required]],
        [SCHEDULE_PERIOD_FIELDS.END_TIME]: [endTime, [Validators.required]],
      },
      {
        validators: MgValidators.TimeComparisonValidator(
          SCHEDULE_PERIOD_FIELDS.START_TIME,
          SCHEDULE_PERIOD_FIELDS.END_TIME,
        ),
      },
    );
  }

  private _getPeriodDefaultTimeRange(lastEndTime?: string): {
    startTime: string;
    endTime: string;
  } {
    const INTERVAL_BETWEEN_PERIODS_MIN = 10;
    const DURATION_MIN = 50;
    const FIRST_PERIOD_START_TIME = '07:30';

    const startTime = lastEndTime
      ? day(lastEndTime, 'HH:mm')
          .add(INTERVAL_BETWEEN_PERIODS_MIN, 'minute')
          .format('HH:mm')
      : FIRST_PERIOD_START_TIME;
    const endTime = day(startTime, 'HH:mm')
      .add(DURATION_MIN, 'minute')
      .format('HH:mm');

    return { startTime, endTime };
  }

  private _setShortCodeListener() {
    this.form
      .get(SCHEDULE_FORM_FIELDS.PERIODS)
      .valueChanges.pipe(
        takeUntil(this._destroyed$),
        map((periods: any[]) => {
          return periods
            .filter((period: any) => period[SCHEDULE_PERIOD_FIELDS.SHORT_CODE])
            .map((period: any) => period[SCHEDULE_PERIOD_FIELDS.SHORT_CODE]);
        }),
      )
      .subscribe(usedShortCodes => {
        this._usedShortCodesSubject.next(usedShortCodes);
      });
  }

  private _initializePeriods(data: any) {
    const periodsControl = this.form.get(
      SCHEDULE_FORM_FIELDS.PERIODS,
    ) as FormArray;

    if (data?.periods?.length > 0) {
      data.periods.forEach((period: any) => {
        periodsControl.push(this._createPeriod(period));
      });
    } else {
      // Set default period if no data
      periodsControl.push(
        this._createPeriod({
          periodName: 'Period 1',
          ...this._getPeriodDefaultTimeRange(),
        }),
      );
    }

    // Update validity
    this.form.updateValueAndValidity();
  }
}
