import { Pipe, PipeTransform } from '@angular/core';

import { ColumnInfo } from 'minga/libraries/shared';

@Pipe({ name: 'reportTableCellClasses' })
export class ReportTableCellClassesPipe implements PipeTransform {
  transform(column: ColumnInfo, isLast: boolean) {
    const classes = {
      'is-number': column?.type === 'number',
      'is-date': column?.type === 'date',
      'is-sortable': column?.sort,
      'add-space': isLast,
    };
    if (column?.width && column?.type)
      classes[`${column.type}-${column.width}`] = true;
    return classes;
  }
}
